import { Flex, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import React from "react";

export const ProviderBalanceCard = ({ key, provider, balance }) => {
  return (
    <Card key={key} bg="#1D2229" border="1px solid #373F48" h="100%">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        py="8px"
        h="100%"
      >
        <Text fontWeight="bold" fontSize="22px" color="#fff">
          เครดิต{" "}
          <Text as="span" fontWeight="normal" fontSize="16px">
            ({provider})
          </Text>
        </Text>
        <Text
          textAlign="center"
          fontSize="24px"
          fontWeight="bold"
          color="green.500"
        >
          ฿ {balance}
        </Text>
      </Flex>
    </Card>
  );
};
