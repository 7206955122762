import { Icon, InputGroup } from "@chakra-ui/react";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import React from "react";

import { OutlinedCalendarIcon } from "../Icons/Icons";

const CustomRangePicker = ({ selectedDates, onDateChange }) => {
  return (
    <InputGroup display="block" w={{ base: "100%", md: "16rem" }}>
      <RangeDatepicker
        bg="purple"
        triggerVariant="input"
        triggerIcon={
          <Icon as={OutlinedCalendarIcon} color="#8C9592" h="18px" w="18px" />
        }
        configs={{
          dateFormat: "dd-MM-yyyy",
        }}
        selectedDates={selectedDates}
        onDateChange={onDateChange}
        propsConfigs={{
          dateNavBtnProps: {
            variant: "outline",
            border: "none",
            borderRadius: "md",
            color: "gray.500",
          },
          dayOfMonthBtnProps: {
            defaultBtnProps: {
              _hover: {
                color: "white",
                background: "green.500",
                fontWeight: "normal",
              },
              borderRadius: "md",
              fontWeight: "normal",
              color: "#fff",
            },
            isInRangeBtnProps: {
              color: "white",
              background: "green.500",
            },
            selectedBtnProps: {
              background: "green.500",
              color: "white",
            },
            todayBtnProps: {
              background: "transparent",
              color: "green.500",
              fontWeight: "bold",
            },
          },
          inputProps: {
            fontSize: "sm",
            pl: { base: "12px", md: "2rem" },
            color: "gray.500",
            w: "100%",
          },
          popoverCompProps: {
            popoverContentProps: {
              background: "rgb(29, 34, 41)",
              color: "#fff",
            },
          },
          calendarPanelProps: {
            wrapperProps: {
              borderColor: "green",
            },
            contentProps: {
              borderWidth: 0,
            },
            headerProps: {
              padding: "5px",
            },
            dividerProps: {
              display: "solid",
            },
          },
          weekdayLabelProps: {
            fontWeight: "normal",
          },
          dateHeadingProps: {
            fontWeight: "normal",
          },
        }}
      />
    </InputGroup>
  );
};

export default CustomRangePicker;
