import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Stack,
  Text,
} from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LineChart from "components/Charts/LineChart";
import { EditIcon, UserIcon, WalletIcon } from "components/Icons/Icons";
import { useErrorHandler } from "hooks/common/useErrorHandler";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchBalanceAllProvider,
  fetchMemberInfo,
  fetchMemberTransactionGraph,
} from "services/member-management/memberProfileService";
import { createLineChartOptions } from "utils/ChartUtils";
import { getMemberLevelColorScheme } from "utils/CommonUtils";
import { formatDate } from "utils/DateformatUtils";
import { memberTransferLogReportColumnsData } from "variables/columns/member-report/memberTransferLogReportColumnsData";
import { ERROR_MESSAGE } from "variables/system";

import CustomRangePicker from "../../../components/CustomRangePicker/CustomRangePicker";
import SearchTableRemote from "../../../components/Tables/SearchTableRemote";
import { useMemberTransferLog } from "../../../hooks/business/useMemberTransferLog";
import { useSearch } from "../../../hooks/common/useSearch";
import { ProviderTypes } from "../../../variables/types";
import ResetPasswordModal from "../../member-management/members/components/ResetPasswordModal";
import ClearTurnoverModal from "./components/ClearTurnoverModal";
import { MemberBankCard } from "./components/MemberBankCard";
import { MemberProfileCard } from "./components/MemberProfileCard";
import MinWithdrawModal from "./components/MinWithdrawModal";
import { ProviderBalanceCard } from "./components/ProviderBalanceCard";
import WithdrawCreditModal from "./components/WithdrawCreditModal";

const MemberProfile = () => {
  const { username } = useParams();

  const [memberData, setMemberData] = useState(null);

  const [isLoading2, setIsLoading2] = useState(true);

  const handleError = useErrorHandler();

  const [modalState, setModalState] = useState({
    withdrawCreditModalOpen: false,
    minWithdrawModalOpen: false,
    resetPasswordModalOpen: false,
    clearTurnoverModalOpen: false,
  });

  const [chartOptions, setChartOptions] = useState({});
  const [memberLineData, setMemberLineData] = useState([]);

  const handleModalOpen = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
  };

  const fetchMemberProfileData = () => {
    const req = {
      username: username,
    };
    fetchMemberInfo(req)
      .then((response) => {
        if (response.status === 200) {
          setMemberData({
            ...response.data.data,
            username,
          });
        }
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const fetchMemberTransactionGraphData = () => {
    const req = {
      username: username,
    };

    setIsLoading2(true);
    fetchMemberTransactionGraph(req)
      .then((response) => {
        if (response.status === 200) {
          const newChartOptions = createLineChartOptions({
            xAxisCategories: response.data.data.labelsDeposit,
          });
          setChartOptions(newChartOptions);
          setMemberLineData([
            {
              name: "Deposit",
              data: response.data.data.dataDeposit,
            },
            {
              name: "Withdraw",
              data: response.data.data.dataWithdraw,
            },
          ]);
          setIsLoading2(false);
        }
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const [providersBalance, setProvidersBalance] = useState([]);
  const fetchAllProviderBalance = () => {
    const req = {
      username: username,
    };
    fetchBalanceAllProvider(req)
      .then((res) => {
        setProvidersBalance(res.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const allProviders = [
    { key: "default", name: "", username: username },
    {
      key: "ufabet",
      name: ProviderTypes.UFABET,
      username: memberData?.ufaUsername,
    },
    {
      key: "betflix",
      name: ProviderTypes.BETFLIX,
      username: memberData?.betflixUsername,
    },
    {
      key: "ambking",
      name: ProviderTypes.AMBKING,
      username: memberData?.ambUsername,
    },
  ];
  const memberCards = allProviders.map((provider) => {
    if (provider.username && !provider.username.includes("null")) {
      return (
        <MemberProfileCard
          key={provider.key}
          provider={provider.name}
          username={provider.username}
        />
      );
    }
  });

  const providerBalanceCards = allProviders
    .filter(
      (provider) =>
        provider.key !== "default" &&
        provider.username &&
        !provider.username.includes("null"),
    )
    .map((provider) => (
      <ProviderBalanceCard
        key={provider.key}
        provider={provider.name}
        balance={
          providersBalance.find(
            (balance) =>
              balance.provider.toUpperCase() === provider.name.toUpperCase(),
          )?.balance || 0
        }
      />
    ));

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };

  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
    null,
    null,
    true,
  );

  const { fetchData } = useMemberTransferLog(username);

  useEffect(() => {
    fetchMemberProfileData();
    fetchMemberTransactionGraphData();
    fetchAllProviderBalance();
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "0px", lg: "5px" }} overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <Grid templateColumns={{ base: "1fr", lg: "1fr 1.5fr" }} gap={4}>
          <GridItem>
            <Card bg="#1D2229" border="1px solid #373F48">
              <Flex direction="column" align="center">
                <Flex justifyContent="space-between" w="100%">
                  <Icon
                    as={UserIcon}
                    mb={2}
                    h="60px"
                    w="60px"
                    color="green.500"
                  />
                  <Badge
                    colorScheme={getMemberLevelColorScheme(
                      memberData?.memberLevelName,
                    )}
                    borderRadius="full"
                    h="22px"
                    w="74px"
                    mb={2}
                    textAlign="center"
                    fontWeight="normal"
                    border="1px solid #373F48"
                  >
                    {memberData?.memberLevelName != null
                      ? memberData?.memberLevelName
                      : "-"}
                  </Badge>
                </Flex>
                <Text
                  fontSize="14px"
                  color={memberData?.memberTagsColor}
                  alignSelf="normal"
                  pt="20px"
                >
                  {username}
                </Text>
                <Text
                  fontSize="22px"
                  mb={2}
                  alignSelf="normal"
                  fontWeight="bold"
                  color="green.500"
                >
                  {memberData?.name}
                </Text>
              </Flex>
              <Grid templateColumns="1fr 1fr" gap={4} pt="18px">
                <Box>
                  <Text fontSize="12px" mb={2}>
                    สมัครสมาชิกเมื่อ
                  </Text>
                  <Text fontSize="14px" color="#fff">
                    {formatDate(memberData?.createDate)}
                  </Text>
                </Box>
                <Box textAlign="right">
                  <Text fontSize="12px" mb={2}>
                    เข้าใช้งานล่าสุด
                  </Text>
                  <Text fontSize="14px" color="#fff">
                    {formatDate(memberData?.lastLoginDate)}
                  </Text>
                </Box>
              </Grid>
            </Card>
          </GridItem>
          <GridItem>
            <Grid
              templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
              gap={4}
            >
              <GridItem colSpan={3}>
                <Card bg="#1D2229" border="1px solid #373F48" h="100%">
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    py="8px"
                    direction={{ base: "column", md: "row" }}
                  >
                    <Flex
                      alignSelf="flex-start"
                      pb={{ base: "18px", md: "0px" }}
                    >
                      <Icon
                        as={WalletIcon}
                        h="70px"
                        w="70px"
                        color="green.500"
                      />
                      <Stack pl="20px">
                        <Text fontWeight="bold" fontSize="22px" color="#fff">
                          เครดิต{" "}
                          <Text as="span" fontWeight="normal" fontSize="16px">
                            (กระเป๋าหลัก)
                          </Text>
                        </Text>
                        <Text fontSize="16px">ยอดเงินคงเหลือที่ใช้ได้</Text>
                      </Stack>
                    </Flex>
                    <Flex
                      w={{ base: "100%", md: "auto" }}
                      justifyContent="space-between"
                    >
                      <Text
                        textAlign="right"
                        fontSize="35px"
                        mr={{ base: "0px", md: "36px" }}
                        fontWeight="bold"
                        color="green.500"
                      >
                        ฿ {memberData?.credit}
                      </Text>
                      <Button
                        background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                        color="#fff"
                        variant="solid"
                        w="110px"
                        fontWeight="normal"
                        fontSize="14px"
                        onClick={() => handleModalOpen("withdrawCreditModal")}
                      >
                        ถอนเงิน
                      </Button>
                    </Flex>
                  </Flex>
                </Card>
              </GridItem>
              <GridItem colSpan={3}>
                <Grid
                  templateColumns={{
                    base: "1fr",
                    md: `repeat(${providerBalanceCards.length}, 1fr)`,
                  }}
                  gap={4}
                >
                  {providerBalanceCards}
                </Grid>
              </GridItem>
              <GridItem colSpan={3}>
                <Grid templateColumns={"repeat(2, 1fr)"} gap={4}>
                  <Card bg="#1D2229" border="1px solid #373F48">
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      direction={{ base: "column", md: "row" }}
                    >
                      <Stack alignSelf="flex-start">
                        <Text fontSize="16px">เทริน์โอเวอร์</Text>
                        <Text
                          textAlign="left"
                          fontSize="24px"
                          color="#fff"
                          fontWeight="bold"
                        >
                          ฿ {memberData?.turnOver}
                        </Text>
                      </Stack>
                      <Button
                        mt={{ base: "12px", md: "0px" }}
                        borderColor={"green.500"}
                        color={"green.500"}
                        variant="outline"
                        fontSize="14px"
                        fontWeight="normal"
                        w="136px"
                        h="42px"
                        onClick={() => handleModalOpen("clearTurnoverModal")}
                      >
                        เคลียร์ยอดเทิร์น
                      </Button>
                    </Flex>
                  </Card>
                  <Card bg="#1D2229" border="1px solid #373F48">
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      direction={{ base: "column", md: "row" }}
                    >
                      <Stack alignSelf="flex-start">
                        <Text fontSize="16px">ถอนขั้นต่ำ</Text>
                        <Text
                          textAlign="left"
                          fontSize="24px"
                          color="#fff"
                          fontWeight="bold"
                        >
                          ฿ {memberData?.minWithdraw}
                        </Text>
                      </Stack>
                      <Button
                        mt={{ base: "12px", md: "0px" }}
                        borderColor={"green.500"}
                        color={"green.500"}
                        w="136px"
                        h="42px"
                        variant="outline"
                        fontSize="14px"
                        fontWeight="normal"
                        onClick={() => handleModalOpen("minWithdrawModal")}
                      >
                        อัพเดทถอนขั้นต่ำ
                      </Button>
                    </Flex>
                  </Card>
                </Grid>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </Animated>
      <Flex
        justifyContent="space-between"
        alignItems={{ base: "left", md: "center" }}
        mt="24px"
        direction={{ base: "column", md: "row" }}
      >
        <Text
          color="white"
          fontSize="18px"
          fontWeight="bold"
          alignSelf="left"
          mb={{ base: "12px", md: "0px" }}
        >
          ยูสเซอร์เข้าเล่นเกม
        </Text>
        <Animated
          duration="0.8s"
          delay="0.1s"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text color="white" fontSize="18px" fontWeight="bold" mr="28px">
            รหัสผ่าน
          </Text>
          <Flex
            justifyContent="space-between"
            bg="#373F48"
            w="288px"
            h="42px"
            alignItems="center"
            px="20px"
            borderRadius="33px"
          >
            <Flex align="center">
              <Text color="#8C9592" fontSize="14px">
                ******************
              </Text>
            </Flex>
            <IconButton
              aria-label="edit"
              bg={"green.500"}
              w="36px"
              h="26px"
              variant="solid"
              fontSize="14px"
              fontWeight="normal"
              onClick={() => {
                handleModalOpen("resetPasswordModal");
              }}
              icon={<EditIcon h="14px" w="14px" color="#fff" />}
            />
          </Flex>
        </Animated>
      </Flex>
      <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
        <Grid
          templateColumns={{
            sm: "1fr",
            lg: `repeat(${providerBalanceCards.length + 1}, 1fr)`,
          }}
          gap={{ base: "0px", md: "24px" }}
        >
          {memberCards}
        </Grid>
      </Animated>
      <Grid
        templateColumns={{ sm: "1fr", lg: "1.8fr 1.2fr" }}
        gap="24px"
        minH="475px"
        background="#262D35"
        p={{ base: "16px", md: "25px" }}
        borderRadius="16px"
        mt="24px"
      >
        <Box>
          <CardHeader display="flex" flexDirection="column">
            <Text color="white" fontSize="lg" fontWeight="bold">
              รายงาน ฝาก/ถอน
            </Text>
            <Text fontSize="10px" color="#8C9592" mt="4px">
              ย้อนหลัง 7 วัน
            </Text>
          </CardHeader>
          <Animated w="100%" h="100%" duration="0.6s" delay="0.1s">
            {isLoading2 ? null : (
              <LineChart
                chartData={memberLineData}
                chartOptions={chartOptions}
              />
            )}
          </Animated>
        </Box>
        <Box>
          <Flex direction="column" w="100%">
            <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
              ข้อมูลบัญชีธนาคาร
            </Text>
            <Animated w="100%" h="100%" duration="0.8s" delay="0.1s" pt="18px">
              <MemberBankCard member={memberData}></MemberBankCard>
            </Animated>
          </Flex>
          <Flex justifyContent="space-between" pt="24px">
            <Box textAlign="right">
              <Text fontSize="12px" mb={2}>
                แหล่งที่มา การตลาด
              </Text>
              <Text fontSize="14px" color="#fff">
                {memberData?.marketing !== null ? memberData?.marketing : "-"}
              </Text>
            </Box>
            <Box>
              <Text fontSize="12px" mb={2}>
                แหล่งที่มา แนะนำเพื่อน
              </Text>
              <Text fontSize="14px" color="#fff">
                {" "}
                {memberData?.affiliateName !== null
                  ? memberData?.affiliateName
                  : "-"}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Grid>
      <Animated duration={0.4} delay={1.2}>
        <Card mt="24px">
          <CardHeader>
            <Flex
              direction={{ base: "column", md: "row" }}
              justifyContent="space-between"
            >
              <Text
                fontSize="18px"
                fontWeight="bold"
                color="#fff"
                pb={{ base: "14px", md: "0px" }}
              >
                รายการโยกเครดิต
              </Text>
              <CustomRangePicker
                selectedDates={selectedDates}
                onDateChange={handleDateChange}
              />
            </Flex>
          </CardHeader>
          <CardBody>
            <Animated w="100%" h="100%" duration="1.0s" delay="0.1s">
              <SearchTableRemote
                query={"wtf"}
                columnsData={memberTransferLogReportColumnsData()}
                fetchData={fetchData}
                search={search}
                setSearch={setSearch}
                showExport={false}
                showAction={false}
                isSearchable={false}
              />
            </Animated>
          </CardBody>
        </Card>
      </Animated>
      <WithdrawCreditModal
        isOpen={modalState.withdrawCreditModalOpen}
        onClose={() => handleModalClose("withdrawCreditModal")}
        username={username}
        onSuccess={fetchMemberProfileData}
      />
      <MinWithdrawModal
        isOpen={modalState.minWithdrawModalOpen}
        onClose={() => handleModalClose("minWithdrawModal")}
        username={username}
        onSuccess={fetchMemberProfileData}
      />
      <ClearTurnoverModal
        isOpen={modalState.clearTurnoverModalOpen}
        onClose={() => handleModalClose("clearTurnoverModal")}
        username={username}
        onSuccess={fetchMemberProfileData}
      />
      <ResetPasswordModal
        isOpen={modalState.resetPasswordModalOpen}
        onClose={() => handleModalClose("resetPasswordModal")}
        member={memberData}
      />
    </Flex>
  );
};

export default MemberProfile;
