import {
  Box,
  Button,
  Flex,
  Image,
  Text, // useBreakpointValue,
} from "@chakra-ui/react";
import avatar8 from "assets/img/avatars/avatar8.png";
import LOGO from "assets/img/logo.png";
import useDashRoutes from "hooks/common/useDashRoutes";
import { useErrorHandler } from "hooks/common/useErrorHandler";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { fetchPendingTransaction } from "../../services/admin/adminProfileService";
import { APP_PROFILE, ERROR_MESSAGE } from "../../variables/system";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import NotificationWebsocket from "../Notification/NotificationWebsocket";
import { SidebarResponsive } from "../Sidebar/Sidebar";

export default function HeaderLinks(props) {
  const [task, setTask] = React.useState({
    depositTask: 0,
    withdrawTask: 0,
  });

  const history = useHistory();
  const handleError = useErrorHandler();

  const [currentLanguage, setCurrentLanguage] = useState("TH");

  const handleLanguageChange = (code) => {
    setCurrentLanguage(code);
    console.log("Language changed to:", code);
  };

  const routes = useDashRoutes();

  useEffect(() => {
    if (!profile) return;
    fetchPendingTransaction()
      .then((response) => {
        setTask(response.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  }, []);

  const profile =
    localStorage.getItem(APP_PROFILE) != null
      ? JSON.parse(localStorage.getItem(APP_PROFILE))
      : null;

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex>
        <SidebarResponsive
          logoText={props.logoText}
          secondary={props.secondary}
          routes={routes}
          username={profile?.username}
          profileUrl={avatar8}
        />
        <Image
          src={LOGO}
          alt="Logo"
          w="96px"
          h="24px"
          display={{ sm: "block", md: "none" }}
          ml="8px"
        />
      </Flex>
      <Box display="flex" alignItems="center">
        <Button
          variant="outline"
          h="30px"
          p="3"
          mr="2"
          borderRadius="24px"
          borderColor="green.500"
          onClick={() => history.push("/admin/transaction-report/deposit")}
        >
          <Text fontWeight="normal" fontSize="sm" color="#fff">
            รอฝาก
          </Text>
          <Text
            ml="2"
            fontSize="xs"
            bg="green.500"
            px="1"
            borderRadius="100px"
            color="#fff"
          >
            {task.depositTask}
          </Text>
        </Button>
        <Button
          variant="outline"
          h="30px"
          p="3"
          mr="2"
          borderRadius="24px"
          borderColor="red.500"
          onClick={() => history.push("/admin/transaction-report/withdraw")}
        >
          <Text fontWeight="normal" fontSize="sm" color="#fff">
            รอถอน
          </Text>
          <Text
            ml="2"
            fontSize="xs"
            bg="red.500"
            px="1"
            borderRadius="100px"
            color="#fff"
          >
            {task.withdrawTask}
          </Text>
        </Button>
        <NotificationWebsocket setTask={setTask} />
        <LanguageSelector
          currentLanguage={currentLanguage}
          handleLanguageChange={handleLanguageChange}
        />
      </Box>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
